import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Context as UserContext } from '../../context/UserContext';
import { Context as SpecialOfferContext } from '../../context/SpecialOfferContext';
import GalleryItem from '../../components/GalleryItem/GalleryItem';
import ChipsList from '../../components/ChipsList/ChipsList';
import Error from '../../components/Error/Error';
import { Paper } from '@material-ui/core';
import specialOfferTemplates from '../../utils/specialOfferTemplates';
import ProductsList from '../../components/ProductsList/ProductsList';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(3),
  },
  progress: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  products: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    '& $textField': {
      marginRight: theme.spacing(2),
      marginBottom: 0,
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
      },
    },
  },
  productRow: {
    display: 'flex',
    marginBottom: theme.spacing(3),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  iconsGroup: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  productsIcon: {
    alignSelf: 'center',
    marginLeft: theme.spacing(1),
  },
  pageActionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
  },
}));

export default function SpecialOfferPage({ onRender }) {
  // VARS
  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams();

  // STATE
  const [name, setName] = useState('');
  const [headline, setHeadline] = useState('');
  const [productName, setProductName] = useState('');
  const [gallery, setGallery] = useState([]);

  // CONTEXTS
  const {
    state: { user },
  } = useContext(UserContext);

  const {
    state: { specialOffer, loading: specialOfferLoading, error: specialOfferError },
    loadSpecialOffer,
    updateSpecialOffer,
    createSpecialOffer,
  } = useContext(SpecialOfferContext);

  // EFFECTS
  useEffect(() => {
    if (user) {
      loadSpecialOffer(id);
    }
    return () => {
      // Reset state when the component unmounts or when navigating to a new offer
      setName('');
      setHeadline('');
      setProductName('');
      setGallery([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, id]);

  useEffect(() => {
    if (specialOffer) {
      onRender(`Special offer - ${specialOffer.name}`);
      // Set values after special offer is loaded
      setName(specialOffer.name);
      setHeadline(specialOffer.headline);
      setProductName(specialOffer.productName);
      setGallery(specialOffer.gallery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialOffer]);

  if (specialOfferError) {
    return <Error error={specialOfferError} />;
  }

  if (!specialOffer) {
    return null;
  }

  function handleSpecialOfferUpdate(update) {
    updateSpecialOffer(id, update);
  }

  function renderGallery() {
    function handleAddGalleryItem() {
      handleSpecialOfferUpdate({
        gallery: [
          ...gallery,
          {
            source: '',
            thumbnail: '',
            type: 'image',
          },
        ],
      });
    }

    function handleUpdateGalleryItem(update, index) {
      const newGallery = [...gallery];
      const entries = Object.entries(update)[0];

      newGallery[index][entries[0]] = entries[1];
      setGallery(newGallery);
      handleSpecialOfferUpdate({ gallery: newGallery });
    }

    function handleDeleteGalleryItem(itemIndex) {
      handleSpecialOfferUpdate({
        gallery: gallery.filter((item, index) => index !== itemIndex),
      });
    }

    return (
      <>
        <Typography variant='h6' gutterBottom>
          Gallery
        </Typography>
        {gallery.map((item, index) => (
          <Box className={classes.products} key={index}>
            <GalleryItem
              key={`${item.source}-${index}`} // Ensure this key is unique for each item
              index={index}
              source={item.source}
              type={item.type}
              thumbnail={item.thumbnail}
              onUpdate={handleUpdateGalleryItem}
            />
            <Box className={classes.iconsGroup}>
              <IconButton
                color='secondary'
                aria-label='Delete'
                component='span'
                size='small'
                onClick={() => handleDeleteGalleryItem(index)}
                className={classes.productsIcon}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                color='primary'
                aria-label='Add'
                component='span'
                size='small'
                onClick={() => handleAddGalleryItem()}
                className={classes.productsIcon}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
        {!gallery.length && (
          <Box className={classes.iconsGroup}>
            <IconButton
              color='primary'
              aria-label='Add'
              component='span'
              size='small'
              onClick={() => handleAddGalleryItem()}
              className={classes.productsIcon}
            >
              <AddIcon />
            </IconButton>
          </Box>
        )}
      </>
    );
  }

  function renderPageActionButtons() {
    async function onCloneClick() {
      const clone = specialOffer;
      delete clone.createdAt;
      delete clone._id;
      delete clone.__v;
      clone.name += ' CLONE';

      try {
        const created = await createSpecialOffer(clone);
        history.push({});
        history.push(`/specialOffers/${created._id}`);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error: ', error);
      }
    }

    return (
      <Box className={classes.pageActionButtons}>
        <Button
          variant='contained'
          color='primary'
          endIcon={<ContentCopyIcon />}
          onClick={() => onCloneClick()}
        >
          Clone
        </Button>
      </Box>
    );
  }

  const Delivered = () => {
    const [value, setValue] = useState('');
    useEffect(() => {
      setValue(specialOffer.delivered);
    }, []);

    return (
      <TextField
        label='Delivered'
        helperText='Ex: 18,500'
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() =>
          handleSpecialOfferUpdate({
            delivered: value,
          })
        }
        fullWidth
        className={classes.textField}
      />
    );
  };

  const Disclaimer = () => {
    const [value, setValue] = useState('');
    useEffect(() => {
      setValue(specialOffer.disclaimer);
    }, []);

    return (
      <TextField
        label='Disclaimer'
        value={value}
        multiline
        minRows={3}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() =>
          handleSpecialOfferUpdate({
            disclaimer: value,
          })
        }
        fullWidth
        className={classes.textField}
      />
    );
  };

  const ShippingText = () => {
    const [value, setValue] = useState('');

    useEffect(() => {
      setValue(specialOffer.shippingText);
    }, []);

    return (
      <TextField
        label='Shipping Text'
        value={value}
        multiline
        minRows={3}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() =>
          handleSpecialOfferUpdate({
            shippingText: value,
          })
        }
        fullWidth
        className={classes.textField}
      />
    );
  };

  const CtaText = () => {
    const [value, setValue] = useState('');

    useEffect(() => {
      setValue(specialOffer.ctaText);
    }, []);

    return (
      <TextField
        label='Cta text'
        value={value}
        onChange={(e) => setValue(e.target.value)}
        helperText='If empty, the default text will be used.'
        onBlur={() =>
          handleSpecialOfferUpdate({
            ctaText: value,
          })
        }
        fullWidth
        className={classes.textField}
      />
    );
  };

  const SubHeadline = () => {
    const [value, setValue] = useState('');

    useEffect(() => {
      setValue(specialOffer.subHeadline);
    }, []);

    return (
      <TextField
        label='Subheadline'
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() =>
          handleSpecialOfferUpdate({
            subHeadline: value,
          })
        }
        fullWidth
        className={classes.textField}
      />
    );
  };

  return (
    <Container maxWidth='lg' className={classes.container}>
      {specialOfferLoading && <LinearProgress className={classes.progress} />}
      {renderPageActionButtons()}
      <Paper className={classes.paper}>
        <TextField
          label='Id'
          value={specialOffer._id}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
          className={classes.textField}
        />
        <TextField
          label='Special Offer Name'
          helperText='Used for internal purposes.'
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={() =>
            handleSpecialOfferUpdate({
              name,
            })
          }
          fullWidth
          className={classes.textField}
        />
        <FormControl fullWidth className={classes.textField}>
          <InputLabel>Template</InputLabel>
          <Select
            value={specialOffer.template.id}
            label='Template'
            onChange={(e) => {
              handleSpecialOfferUpdate({
                template: {
                  id: e.target.value,
                  name: specialOfferTemplates[e.target.value],
                },
              });
            }}
          >
            {specialOfferTemplates.map((name, index) => (
              <MenuItem key={index} value={index}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label='Product Name'
          value={productName}
          helperText='Used on the client facing special offer page.'
          onChange={(e) => setProductName(e.target.value)}
          onBlur={() =>
            handleSpecialOfferUpdate({
              productName,
            })
          }
          fullWidth
          className={classes.textField}
        />
        <TextField
          label='Headline'
          value={headline}
          onChange={(e) => setHeadline(e.target.value)}
          onBlur={() =>
            handleSpecialOfferUpdate({
              headline,
            })
          }
          fullWidth
          className={classes.textField}
        />
        <SubHeadline />
        <Delivered />
        <Disclaimer />
        <ShippingText />
        <CtaText />
        <ChipsList
          items={specialOffer.benefits}
          onUpdate={(newItems) => handleSpecialOfferUpdate({ benefits: newItems })}
          title='Benefits'
        />
        {renderGallery()}
        <ChipsList
          items={specialOffer.productIds}
          onUpdate={(newItems) => handleSpecialOfferUpdate({ productIds: newItems })}
          title='Product Ids'
        />
        <FormControl fullWidth className={classes.textField}>
          <InputLabel>Products sorting</InputLabel>
          <Select
            value={specialOffer.productsSorting}
            onChange={(e) => {
              handleSpecialOfferUpdate({
                productsSorting: e.target.value,
              });
            }}
          >
            <MenuItem value={'asc'}>Ascending</MenuItem>
            <MenuItem value={'desc'}>Descending</MenuItem>
          </Select>
        </FormControl>
        {specialOffer.productIds.length > 0 && (
          <ProductsList
            offer={specialOffer}
            updateFunction={updateSpecialOffer}
            onSyncClick={() => handleSpecialOfferUpdate({ productIds: specialOffer.productIds })}
          />
        )}
      </Paper>
    </Container>
  );
}

SpecialOfferPage.propTypes = {
  onRender: PropTypes.func,
};
