import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import ImageInput from '../ImageInput/ImageInput';
import { getPriceWithCurrency } from '../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  cardsContainer: {
    display: 'flex',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    overflowX: 'scroll',
    flexWrap: 'wrap',
  },
  title: {
    fontWeight: '500',
    marginBottom: theme.spacing(1),
  },
  productCard: {
    width: `calc(25% - ${theme.spacing(2)}px)`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    margin: theme.spacing(1),
    padding: 0,
  },
  productCardPopular: {
    color: 'white',
    textAlign: 'center',
    backgroundColor: '#4050b5',
  },
  productCardContent: {
    flex: '1',
  },
  syncButton: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

export default function ProductsList({ offer, onSyncClick, updateFunction }) {
  const classes = useStyles();
  const [productEdits, setProductEdits] = useState({});

  useEffect(() => {
    const edits = offer.productsData.reduce((acc, product) => {
      acc[product.id] = {
        offerId: offer.productDetails[product.id]?.offerId ?? '',
        billingModelId: offer.productDetails[product.id]?.billingModelId ?? '',
        image: offer.productDetails[product.id]?.image ?? '',
        subtitle: offer.productDetails[product.id]?.subtitle ?? '',
        bestValue: offer.productDetails[product.id]?.bestValue ?? false,
        mostPopular: offer.productDetails[product.id]?.mostPopular ?? false,
      };
      return acc;
    }, {});
    setProductEdits(edits);
  }, [offer.productsData, offer.productDetails]);

  const handleFieldChange = (productId, field, value) => {
    setProductEdits((prevEdits) => ({
      ...prevEdits,
      [productId]: {
        ...prevEdits[productId],
        [field]: value,
      },
    }));
  };

  const handleCheckboxChange = (productId, field, isChecked) => {
    // Create the updated product edit
    const updatedProductEdit = {
      ...productEdits[productId],
      [field]: isChecked,
    };

    // Update the local state
    setProductEdits((prevEdits) => ({
      ...prevEdits,
      [productId]: updatedProductEdit,
    }));

    // Pass the updated product edit to handleFieldBlur
    handleFieldBlur(productId, updatedProductEdit);
  };

  const handleFieldBlur = (productId, updatedProductEdit = null) => {
    const productEdit = updatedProductEdit ?? productEdits[productId];

    const updatedDetails = {
      ...offer.productDetails,
      [productId]: {
        ...offer.productDetails[productId],
        ...productEdit,
      },
    };

    updateFunction(offer._id, { productDetails: updatedDetails });
  };

  return (
    <>
      <Box className={classes.cardsContainer}>
        {offer.productsData.map((item) => (
          <Card key={item.id} variant='outlined' className={classes.productCard}>
            <CardContent className={classes.productCardContent}>
              <Typography className={classes.title} variant='h6' gutterBottom>
                Sticky PID - {item.id}
              </Typography>
              <Typography className={classes.textField} variant='body1' gutterBottom>
                {item.name}
              </Typography>

              <TextField
                fullWidth
                className={classes.textField}
                label='Sticky Offer ID'
                value={productEdits[item.id]?.offerId ?? ''}
                onChange={(e) => handleFieldChange(item.id, 'offerId', e.target.value)}
                onBlur={() => handleFieldBlur(item.id)}
              />
              <TextField
                fullWidth
                className={classes.textField}
                label='Billing Model ID'
                value={productEdits[item.id]?.billingModelId ?? ''}
                onChange={(e) => handleFieldChange(item.id, 'billingModelId', e.target.value)}
                onBlur={() => handleFieldBlur(item.id)}
              />
              <TextField
                fullWidth
                className={classes.textField}
                label='Subtitle'
                helperText='Optional'
                value={productEdits[item.id]?.subtitle ?? ''}
                onChange={(e) => handleFieldChange(item.id, 'subtitle', e.target.value)}
                onBlur={() => handleFieldBlur(item.id)}
              />
              <ImageInput
                fullWidth
                className={classes.textField}
                label='Image URL'
                value={productEdits[item.id]?.image ?? ''}
                onChange={(e) => handleFieldChange(item.id, 'image', e.target.value)}
                onBlur={() => handleFieldBlur(item.id)}
              />

              <Typography style={{ fontWeight: 'bold' }} variant='caption'>
                Price - {getPriceWithCurrency(item.price, offer.locale, offer.currency)}
              </Typography>
              <Typography variant='caption' style={{ display: 'block' }}>
                Quantity: {item.quantity}
              </Typography>
              <Typography variant='caption' style={{ display: 'block' }}>
                Shipping id: {item.shippingId} (
                {getPriceWithCurrency(item.shippingPrice, offer.locale, offer.currency)})
              </Typography>
              <Typography variant='caption' style={{ display: 'block' }}>
                MSRP - {getPriceWithCurrency(item.oldPrice, offer.locale, offer.currency)}
              </Typography>
              {item.soldOut && (
                <Typography variant='caption' style={{ display: 'block' }}>
                  <b style={{ color: 'red' }}>SOLD OUT</b>
                </Typography>
              )}

              <FormControlLabel
                control={
                  <Checkbox
                    checked={productEdits[item.id]?.mostPopular ?? false}
                    onChange={(e) => handleCheckboxChange(item.id, 'mostPopular', e.target.checked)}
                    name='mostPopular'
                    color='primary'
                  />
                }
                label='Most Popular'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={productEdits[item.id]?.bestValue ?? false}
                    onChange={(e) => handleCheckboxChange(item.id, 'bestValue', e.target.checked)}
                    name='bestValue'
                    color='primary'
                  />
                }
                label='Best Value'
              />
            </CardContent>
          </Card>
        ))}
      </Box>
      <Button variant='contained' color='primary' className={classes.syncButton} onClick={onSyncClick}>
        Sync products
      </Button>
    </>
  );
}
