/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import LoginPage from './pages/LoginPage/LoginPage';
import HomePage from './pages/HomePage/HomePage';
import NotFound from './pages/NotFound/NotFound';
import Header from './components/Header/Header';
import { Context as UserContext } from './context/UserContext';
import OfferPage from './pages/OfferPage/OfferPage';
import OffersPage from './pages/OffersPage/OffersPage';
import SpecialOffersPage from './pages/SpecialOffersPage/SpecialOffersPage';
import SpecialOfferPage from './pages/SpecialOfferPage/SpecialOfferPage';
import ReviewsPage from './pages/ReviewsPage/ReviewsPage';
import ReviewPage from './pages/ReviewPage/ReviewPage';
import FreeProductsPage from './pages/FreeProductsPage/FreeProductsPage';
import FreeProductPage from './pages/FreeProductPage/FreeProductPage';
import PromosPage from './pages/PromosPage/PromosPage';
import PromoPage from './pages/PromoPage/PromoPage';
import CrossSellsPage from './pages/CrossSellsPage/CrossSellsPage';
import CrossSellPage from './pages/CrossSellPage/CrossSellPage';
import WebsitesPage from './pages/WebsitesPage/WebsitesPage';
import BannersPage from './pages/BannersPage/BannersPage';
import ProofPopupsPage from './pages/ProofPopups/ProofPopups';
import UrlParamsPage from './pages/UrlParamsPage/UrlParamsPage';
import UsersPage from './pages/UsersPage/UsersPage';
import PaymentRoutersPage from './pages/PaymentRoutersPage/PaymentRoutersPage';
import VerifiHistoryPage from './pages/VerifiHistoryPage/VerifiHistoryPage';
import DeclineSettingsPage from './pages/DeclineSettingsPage/DeclineSettingsPage';
import ListiclesPage from './pages/ListiclesPage/ListiclesPage';
import ListiclePage from './pages/ListiclePage/ListiclePage';
import MediaLibraryPage from './pages/MediaLibraryPage/MediaLibraryPage';

export default function App() {
  const history = useHistory();
  const [pageName, setPageName] = useState('');

  const {
    state: { user, isTokenValid },
    validate,
    logout,
  } = useContext(UserContext);

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      return history.push('/login');
    }

    if (localStorage.getItem('token')) {
      validate({ token: localStorage.getItem('token') });
    }
  }, []);

  useEffect(() => {
    if (!isTokenValid) {
      history.push('/login');
    }
  }, [isTokenValid]);

  async function handleLogout(id) {
    await logout(id);
    localStorage.removeItem('token');
    history.push('/login');
  }

  return (
    <>
      <Header pageName={pageName} user={user} onLogout={handleLogout} />
      <Switch>
        <Route path='/login'>
          <LoginPage />
        </Route>
        <Route path='/offers/:id'>
          <OfferPage onRender={(name) => setPageName(name)} />
        </Route>
        <Route exact path='/offers'>
          <OffersPage onRender={(name) => setPageName(name)} />
        </Route>
        <Route path='/specialOffers/:id'>
          <SpecialOfferPage onRender={(name) => setPageName(name)} />
        </Route>
        <Route exact path='/specialOffers'>
          <SpecialOffersPage onRender={(name) => setPageName(name)} />
        </Route>
        <Route exact path='/paymentRouters'>
          <PaymentRoutersPage onRender={(name) => setPageName(name)} />
        </Route>
        <Route exact path='/promos'>
          <PromosPage onRender={(name) => setPageName(name)} />
        </Route>
        <Route path='/promos/:id'>
          <PromoPage onRender={(name) => setPageName(name)} />
        </Route>
        <Route exact path='/reviews'>
          <ReviewsPage onRender={(name) => setPageName(name)} />
        </Route>
        <Route path='/reviews/:id'>
          <ReviewPage onRender={(name) => setPageName(name)} />
        </Route>
        <Route exact path='/freeProducts'>
          <FreeProductsPage onRender={(name) => setPageName(name)} />
        </Route>
        <Route path='/freeProducts/:id'>
          <FreeProductPage onRender={(name) => setPageName(name)} />
        </Route>
        <Route exact path='/crossSells'>
          <CrossSellsPage onRender={(name) => setPageName(name)} />
        </Route>
        <Route path='/crossSells/:id'>
          <CrossSellPage onRender={(name) => setPageName(name)} />
        </Route>
        <Route exact path='/websites'>
          <WebsitesPage onRender={(name) => setPageName(name)} />
        </Route>
        <Route exact path='/banners'>
          <BannersPage onRender={(name) => setPageName(name)} />
        </Route>
        <Route exact path='/proofPopups'>
          <ProofPopupsPage onRender={(name) => setPageName(name)} />
        </Route>
        <Route exact path='/urlParams'>
          <UrlParamsPage onRender={(name) => setPageName(name)} />
        </Route>
        <Route exact path='/verifiHistory'>
          <VerifiHistoryPage onRender={(name) => setPageName(name)} />
        </Route>
        <Route exact path='/listicles'>
          <ListiclesPage onRender={(name) => setPageName(name)} />
        </Route>
        <Route path='/listicles/:id'>
          <ListiclePage onRender={(name) => setPageName(name)} />
        </Route>

        <Route exact path='/users'>
          <UsersPage onRender={(name) => setPageName(name)} />
        </Route>
        <Route exact path='/declineSettings'>
          <DeclineSettingsPage onRender={(name) => setPageName(name)} />
        </Route>
        <Route exact path='/mediaLibrary'>
          <MediaLibraryPage onRender={(name) => setPageName(name)} />
        </Route>

        <Route exact path='/'>
          <HomePage onRender={(name) => setPageName(name)} />
        </Route>
        <Route component={NotFound} />
      </Switch>
    </>
  );
}
