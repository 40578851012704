import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';
import App from './App';

import { Provider as OrdersProvider } from './context/OrdersContext';
import { Provider as OffersProvider } from './context/OffersContext';
import { Provider as SpecialOffersProvider } from './context/SpecialOffersContext';
import { Provider as SpecialOfferProvider } from './context/SpecialOfferContext';
import { Provider as OfferProvider } from './context/OfferContext';
import { Provider as UserProvider } from './context/UserContext';
import { Provider as ReviewsProvider } from './context/ReviewsContext';
import { Provider as ReviewProvider } from './context/ReviewContext';
import { Provider as FreeProductsProvider } from './context/FreeProductsContext';
import { Provider as FreeProductProvider } from './context/FreeProductContext';
import { Provider as PromosProvider } from './context/PromosContext';
import { Provider as PromoProvider } from './context/PromoContext';
import { Provider as CrossSellsProvider } from './context/CrossSellsContext';
import { Provider as CrossSellProvider } from './context/CrossSellContext';
import { Provider as AnalyticsProvider } from './context/AnalyticsContext';
import { Provider as AffIdsProvider } from './context/AffIdsContext';
import { Provider as WebsitesPageProvider } from './context/WebsitesContext';
import { Provider as BannersPageProvider } from './context/BannersContext';
import { Provider as ProofPopupsProvider } from './context/ProofPopupsContext';
import { Provider as UsersPageProvider } from './context/UsersContext';
import { Provider as ListiclesPageProvider } from './context/ListiclesContext';
import { Provider as ListiclePageProvider } from './context/ListicleContext';

import './global.css';

const theme = createTheme({
  props: {
    MuiTextField: {
      variant: 'outlined',
      size: 'small',
    },
    MuiFormControl: {
      variant: 'outlined',
      size: 'small',
    },
    MuiTable: {
      size: 'small',
    },
  },
  overrides: {
    MuiInputLabel: {
      root: {
        margin: '0 -3px',
        padding: '0 6px',
        backgroundColor: 'white',
      },
    },
    MuiFormHelperText: {
      root: {
        fontStyle: 'italic',
        lineHeight: 'normal',
      },
    },
    MuiContainer: {
      maxWidthLg: { maxWidth: '1440px !important' },
    },
  },
});

ReactDOM.render(
  <UsersPageProvider>
    <ListiclesPageProvider>
      <ListiclePageProvider>
        <ProofPopupsProvider>
          <BannersPageProvider>
            <WebsitesPageProvider>
              <AffIdsProvider>
                <AnalyticsProvider>
                  <CrossSellsProvider>
                    <CrossSellProvider>
                      <FreeProductsProvider>
                        <FreeProductProvider>
                          <ReviewProvider>
                            <ReviewsProvider>
                              <PromosProvider>
                                <PromoProvider>
                                  <UserProvider>
                                    <OffersProvider>
                                      <OfferProvider>
                                        <SpecialOffersProvider>
                                          <SpecialOfferProvider>
                                            <OrdersProvider>
                                              <Router>
                                                <ThemeProvider theme={theme}>
                                                  <CssBaseline />
                                                  <App />
                                                </ThemeProvider>
                                              </Router>
                                            </OrdersProvider>
                                          </SpecialOfferProvider>
                                        </SpecialOffersProvider>
                                      </OfferProvider>
                                    </OffersProvider>
                                  </UserProvider>
                                </PromoProvider>
                              </PromosProvider>
                            </ReviewsProvider>
                          </ReviewProvider>
                        </FreeProductProvider>
                      </FreeProductsProvider>
                    </CrossSellProvider>
                  </CrossSellsProvider>
                </AnalyticsProvider>
              </AffIdsProvider>
            </WebsitesPageProvider>
          </BannersPageProvider>
        </ProofPopupsProvider>
      </ListiclePageProvider>
    </ListiclesPageProvider>
  </UsersPageProvider>,
  document.getElementById('root')
);

document.addEventListener('visibilitychange', () => {
  if (document.visibilityState !== 'visible') {
    document.querySelectorAll('input').forEach((item) => {
      item.blur();
    });
  }
});
